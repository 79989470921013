<template>
  <div>
    <b-button
      variant="success"
      @click="addDocModal"
      size="sm"
    >
      {{btnLabel}}
    </b-button>
    <modal-add-plafond
      id="addPlafond"
      :entityType="entityClass"
      :entityId="entityId"
      :on-success="onAddSuccess"
    />
  </div>
</template>

<script>
const ModalAddPlafond = () => import('./ModalAddPlafond.vue');

export default {
  name: 'NewPlafond',
  components: { ModalAddPlafond },
  props: {
    entityClass: String,
    entityId: String,
    onSuccess: Function,
  },
  computed: {
    btnLabel() {
      return 'Aggiungi plafond';
    },
  },
  methods: {
    addDocModal() {
      this.$bvModal.show('addPlafond');
    },
    onAddSuccess(data) {
      this.$bvModal.hide('addPlafond');
      if (this.onSuccess) {
        this.onSuccess(data);
      }
    },
  },
};
</script>

<style scoped>

</style>
